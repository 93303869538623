import { gql } from 'apollo-angular';

export const DRIVER_BOUNDARY_FRAGMENT = gql`
    fragment DriverBoundary on DriverBoundary {
        id
        createdAt
        updatedAt
        name
        schedule
        pickUpArea
        deliveryArea
    }
`;

export const DRIVER_FRAGMENT = gql`
    fragment Driver on Driver {
        id
        createdAt
        updatedAt
        name
        phoneNumber
        onfleetWorkerId
        boundaries {
            ...DriverBoundary
        }
    }
    ${DRIVER_BOUNDARY_FRAGMENT}
`;

export const GET_DRIVER = gql`
    query GetDriver($id: ID!) {
        driver(id: $id) {
            ...Driver
        }
    }
    ${DRIVER_FRAGMENT}
`;

export const GET_DRIVER_LIST = gql`
    query GetDriverList($options: DriverListOptions) {
        drivers(options: $options) {
            items {
                ...Driver
            }
            totalItems
        }
    }
    ${DRIVER_FRAGMENT}
`;

export const ADD_DRIVER_BOUNDARY = gql`
    mutation AddDriverBoundary($input: AddDriverBoundaryInput!) {
        addDriverBoundary(input: $input) {
            ...DriverBoundary
        }
    }
    ${DRIVER_BOUNDARY_FRAGMENT}
`;

export const UPDATE_DRIVER_BOUNDARY = gql`
    mutation UpdateDriverBoundary($input: UpdateDriverBoundaryInput!) {
        updateDriverBoundary(input: $input) {
            ...DriverBoundary
        }
    }
    ${DRIVER_BOUNDARY_FRAGMENT}
`;

export const DELETE_DRIVER_BOUNDARY = gql`
    mutation DeleteDriverBoundary($id: ID!) {
        deleteDriverBoundary(id: $id) {
            result
            message
        }
    }
`;