import {
    AddDriverBoundary,
    AddDriverBoundaryInput,
    UpdateDriverBoundary,
    UpdateDriverBoundaryInput,
    DeleteDriverBoundary,
} from '../../common/generated-types';
import {
    ADD_DRIVER_BOUNDARY,
    UPDATE_DRIVER_BOUNDARY,
    DELETE_DRIVER_BOUNDARY,
} from '../definitions/driver-definitions';

import { BaseDataService } from './base-data.service';

export class DriverDataService {
    constructor(private baseDataService: BaseDataService) { }

    addDriverBoundary(input: AddDriverBoundaryInput) {
        return this.baseDataService.query<AddDriverBoundary.Mutation, AddDriverBoundary.Variables>(
            ADD_DRIVER_BOUNDARY,
            { input },
        );
    }

    updateDriverBoundary(input: UpdateDriverBoundaryInput) {
        return this.baseDataService.mutate<UpdateDriverBoundary.Mutation, UpdateDriverBoundary.Variables>(
            UPDATE_DRIVER_BOUNDARY,
            { input },
        );
    }

    deleteDriverBoundary(id: string) {
        return this.baseDataService.mutate<DeleteDriverBoundary.Mutation, DeleteDriverBoundary.Variables>(
            DELETE_DRIVER_BOUNDARY,
            { id },
        );
    }
}
